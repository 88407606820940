<template>

  <div>
    <el-upload :action="acrion" :headers="headers" list-type="picture-card" :show-file-list="true" :file-list="list"
               :before-upload="beforeAvatarUpload" :on-success="handelUploadSuccess" :on-progress="uploadProcess">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <el-progress v-if="!file.file_name" type="circle" :percentage="uploadPercent"></el-progress>
        <el-image v-if="file.type=='image' && file.unique" style="height: 150px;"
                  class="el-upload-list__item-thumbnail" :src="`/api/attachment/download/${file.unique}`"
                  :alt="file.file_name">
          <div slot="placeholder" class="image-slot">
            <img src="../assets/loading.gif"/>
          </div>
        </el-image>
        <div v-else-if="file.type=='word'"
             style="height: 150px;background-color: deepskyblue;color: white;text-align: center;">
          <h6 style="padding: 30% 0 30% 0;">{{ file.file_name }}</h6>
        </div>
        <div v-else-if="file.type=='pdf'"
             style="height: 150px;background-color: lightcoral;color: white;text-align: center;">
          <h6 style="padding: 30% 0 30% 0;">{{ file.file_name }}</h6>
        </div>
        <span class="el-upload-list__item-actions">
					<span v-if="file.type=='image'" class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)">
						<i class="el-icon-zoom-in"></i>
					</span>
					<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
						<i class="el-icon-download"></i>
					</span>
					<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
						<i class="el-icon-delete"></i>
					</span>
				</span>
      </div>
    </el-upload>
    <el-image-viewer v-if="preview_show" :urlList="preview_list" :on-close="closeViewer"></el-image-viewer>
  </div>

</template>

<script>
import * as imageConversion from 'image-conversion';
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    fileFormat: {
      type: Array,
      default: () => ['png', 'jpg', 'doc', 'docx', 'pdf']
    },
    fileSize: {
      type: Number,
      default: () => 30
    }
  },
  created() {

    this.acrion = '/api/attachment/upload'
    this.headers.Authorization = sessionStorage.getItem('token')
  },
  data() {
    return {
      disabled: false,
      acrion: '',
      headers: {
        Authorization: ''
      },
      list: [],
      preview_show: false,
      preview_list: [],
      uploadPercent: 0,
    };
  },
  watch: {
    value(newValue, OldValue) {
      if (newValue)
        this.list = newValue;

      console.log(newValue)

    }
  },
  methods: {
    closeViewer() {
      this.preview_show = false;
    },
    handleRemove(file) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i] == file) {
          this.list.splice(i, 1);
          break;
        }
      }
    },
    handlePictureCardPreview(file) {
      let array = []
      array.push('/api/attachment/download/' + file.unique)
      this.list.map(item => {
        if (item.unique !== file.unique) array.push('/api/attachment/download/' + item.unique)
      })
      this.preview_show = true;
      this.preview_list = array;
    },
    handleDownload(file) {
      window.location.href = "/api/attachment/download/" + file.unique;
    },
    beforeAvatarUpload(file) {

      return new Promise((resolve, reject) => {

        let file_format_check = false;
        for (let i = 0; i < this.fileFormat.length; i++) {
          if (file.name.toLowerCase().endsWith(this.fileFormat[i])) {
            file_format_check = true;
            break;
          }
        }

        if (!file_format_check) {
          this.$message.error('上传文件只能是' + this.fileFormat.toString());
          reject();
        }

        if (!(file.size / 1024 / 1024 < this.fileSize)) {
          this.$message.error('上传文件大小不能超过 ' + this.fileSize + 'MB!');
          reject();
        }

        if (file.name.toLowerCase().endsWith('png') || file.name.toLowerCase().endsWith('jpg')) {
          imageConversion.compressAccurately(file, {
            size: 0.3 * 1024,
            type: file.type,
            scale: Math.min(1 / (file.size / parseInt(0.3 * 1024 * 1024)), 1),
          }).then(res => {
            resolve(res)
          })

        } else {
          resolve(file)
        }
      })
    },
    handelUploadSuccess(res) {
      this.list.push(res.data)
      this.$emit('input', this.list)
    },
    uploadProcess(event, file, fileList) {
      this.uploadPercent = Math.floor(event.percent)
    },
  }
}
</script>

<style>

</style>
